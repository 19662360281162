<template>
  <div class="container1" @touchmove="handleMove" @click="handleMove" id="container1">
    <div class="avatar-bg">
      <img :src="image" v-if="image" alt="" class="avatar">
      <img v-else src="../assets/images/default_avatar.png" alt="" class="avatar">
      <div class="upload">
        <span class="icon-upload" style="font-size:0.24rem;color: #131314;"></span>
      </div>
      <h5-cropper class="apple" :option="option" @getbase64="getbase64Data" @getblobData="getBlob" @get-file="getFile"
        cancelButtonText="111" cancelButtonBackgroundColor="red"></h5-cropper>
    </div>


    <!-- <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" /> -->

    <input class="edit-nick" placeholder="请输入您的昵称" v-model.trim="nickname" @click.stop="" @blur="checkFocusStatus" />

    <div class="group">
      <div class="row">
        <div class="t">性别</div>
        <div class="expand"></div>
        <div :class="isBoy ? 'gender boy boy-sel' : 'gender boy'" @click="isBoy = true">

        </div>
        <div style="width:0.16rem"></div>
        <div :class="isBoy ? 'gender girl' : 'gender girl girl-sel'" @click="isBoy = false"></div>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="t">地区</div>
        <div class="expand"></div>
        <div class="city">中国 北京</div>
        <span class="icon-right" style="font-size:0.2rem;color: #74747A;"></span>
      </div>
      <div class="line"></div>
      <div class="row">
        <div class="t">简介</div>
      </div>
      <textarea id="desc" class="desc" v-model.trim="intro" placeholder="请填写您的简介（选填）" @click.stop=""
        @blur="checkFocusStatus"></textarea>
    </div>
    <div style="height:2rem;flex-shrink: 0;"></div>

    <div class="footer">
      <a class="link_btn0 btn" @click="commit">保存</a>
      <div style="height:0.28rem"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import H5Cropper from 'vue-cropper-h5'
import MonitorKeyboard from '../config/monitor_keyboard.js'
import { isiOS } from '../config/utils';
import inobounce from 'inobounce';
export default {
  props: {
    msg: String
  },
  data() {
    return {
      option: {
        cancelButtonBackgroundColor: "#A5A5AD",//取消按钮背景色
        confirmButtonBackgroundColor: "#FFF700",//确定按钮背景色
        cancelButtonTextColor: "#131314",//取消按钮字体色
        confirmButtonTextColor: "#131314",//确定按钮字体色
      }, //配置
      image: null,
      nickname: '',
      isBoy: true,
      intro: ''
    }
  },
  components: {
    H5Cropper
  },
  beforeDestroy() {
    inobounce.disable()
    this.monitorKeyboard.onEnd()
  },
  mounted() {
    this.getData()
    var textarea = document.querySelector('#desc');

    textarea.addEventListener('input', (e) => {
      textarea.style.height = 'auto'
      textarea.style.height = e.target.scrollHeight + 'px';
    });

    $('window').on('touchmove', function (event) {
      event.preventDefault()
    })
    if (isiOS()) {
      inobounce.enable()
    }
    this.getKeyboardState();
  },
  methods: {
    handleMove() {
      $('.edit-nick').blur();
      $('#desc').blur();
      this.timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 20);
    },
    checkFocusStatus() {
      if (document.activeElement != $('.edit-nick') && document.activeElement != $('#desc')) {
        this.timer = setTimeout(() => {
          window.scrollTo(0, 0);
        }, 20);
      }
    },
    getbase64Data(data) {
      this.image = data;
    },
    getBlob(blob) {
    },
    getFile(file) {
      var fr = new FileReader();
      var _this = this;
      fr.onload = function () {
        _this.image = fr.result;
      };
      fr.readAsDataURL(file);

      this.uploadFile(file)
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        $('.footer').hide();
      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        $('.footer').show();
      })
    },
    getData() {
      var _this = this;
      this.$get(this.$api.userInfo).then(data => {
        _this.nickname = data.nickname
        _this.isBoy = data.sex == 'MALE'
        _this.intro = data.signature
        _this.image = data.avatar

        var textarea = document.querySelector('#desc');
        setTimeout(() => {
          var height = textarea.scrollHeight < 44 ? 44 : textarea.scrollHeight
          textarea.style.height = height + 'px';
        }, 20)
      })
    },
    commit() {
      if (this.image == null) {
        this.$toast('请上传头像', require('../assets/images/toast_tip.png'))
        return
      }
      if (this.nickname.length == 0) {
        this.$toast('请输入昵称', require('../assets/images/toast_tip.png'))
        return
      }
      this.$post(this.$api.userInfo, {
        nickname: this.nickname, 'sex': this.isBoy ? 'MALE' : 'FEMALE',
        'signature': this.intro,
        'area': '北京',
        'city': '北京',
        'province': '北京',
        'country': '中国'
      }).then(data => {
        this.$toast('更新成功', require('../assets/images/toast_success.png'))
        setTimeout(() => {
          if (global.index) {
            global.index.refresh()
          }
          this.$router.push('/').catch(err=>{})
        }, 2000)

      })

    },
    onFileSelect(e) {
      let file = e.target.files[0]
      // console.log(file)

      var fr = new FileReader();
      var _this = this;
      fr.onload = function () {
        _this.image = fr.result;
      };
      fr.readAsDataURL(file);

      this.uploadFile(file)
    },
    uploadFile(file) {
      this.$get(this.$api.ossFormUpload, {
        type: 'AVATAR',
        file_ext: 'png'
      }).then(data => {
        var url = data.upload_url;
        var strUrl = data.view_url;

        let params = new FormData()

        params.append('OSSAccessKeyId', data.fields.OSSAccessKeyId)
        params.append('Signature', data.fields.Signature)
        params.append('key', data.fields.key)
        params.append('policy', data.fields.policy)
        params.append('file', file, file.name);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' }

        }
        axios.post(url, params, config).then(res => {
          this.$post(this.$api.userInfo, { avatar: strUrl }).then(data => {
          })
        }).catch(error => {
        })
      })
    }
  }
}
</script>
<style lang='scss' scoped>
:deep(.bg) {
  position: fixed;
  top: 0;
  height: 100% !important;
  width: 100%;
  left: 0;
  z-index: 521;
}

:deep(.btndiv) {
  height: 13.333vw;
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 4vw;
  line-height: 13.333vw;
  font-size: 4vw;
  position: absolute !important;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.container1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  color: #fff;
  -webkit-overflow-scrolling: touch;
}

.avatar-bg {
  margin-top: 0.4rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.7rem;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    width: 1.4rem;
    height: 1.4rem;
    object-fit: cover;
  }

  .upload {
    height: 0.28rem;
    background-color: #FFF700;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
  }
}

.edit-nick {
  width: 2.79rem;
  height: 0.18rem;
  padding: 0.25rem 0;
  background: #2C2C2E;
  border-radius: 0.16rem;
  border: none;
  text-align: center;
  font-size: 0.18rem;
  margin-top: 0.24rem;
  flex-shrink: 0;
  box-sizing: border-box;
  caret-color: #FF9900FF;
}

.group {
  margin-top: 0.32rem;
  width: 3.43rem;
  box-sizing: border-box;
  background: #2C2C2E;
  border-radius: 0.24rem;
  padding: 0.2rem;

  .t {
    color: #FFF700;
    font-size: 0.16rem;
    font-weight: bold;
  }

  .line {
    height: 1px;
    background-color: #444447FF;
    margin: 0.18rem 0;
  }

  .gender {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.2rem;

    background-size: 0.24rem 0.24rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #444447FF;
  }

  .boy {
    background-image: url('../assets/images/boy.png');
  }

  .boy-sel {
    background-color: #00FFFFFF;
  }

  .girl {
    background-image: url('../assets/images/girl.png');
  }

  .girl-sel {
    background-color: #FF00AAFF;
  }

  .city {
    color: #fff;
    font-size: 0.14rem;
  }

  .desc {
    background-color: transparent;
    width: 100%;
    height: 44px;
    font-size: 0.14rem;
    line-height: 0.2rem;
    margin-top: 0.2rem;
    padding: 0;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-image: linear-gradient(180deg, rgba(19, 19, 20, 0.00) 0%, #131314 25%);
}

// ::v-deep .bg{
//   position: fixed;
//   left: 0;
//   top: 0;
//   bottom: 100px;
//   right: 0;
// }

// ::v-deep .btndiv {
//     height: 13.333vw;
//     color: #fff;
//     justify-content: space-between;
//     display: flex;
//     align-items: center;
//     padding: 0 4vw;
//     line-height: 13.333vw;
//     font-size: 4vw;
//     position: absolute;
//     left: 0;
//     bottom:0;
//     right: 0;
// }
</style>
